import Box from "@mui/material/Box";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import React from "react";
import {useDealContext} from "../../contexts/DealContext";

export const DealPageAdditionalInfo = () => {
    const {
        dealState,
        setDealStateWithUpdate,
        updateDealResponse,
    } = useDealContext()

    const isLoading = updateDealResponse?.loading
    return (
        <Box
            className={classnames("dealPagePayment-column leftColumn")}
            style={{
                minWidth: '365px',
                height: '100%',
                marginLeft: '20px',
                marginTop: "98px",
                minHeight: "574px"
            }}
        >

            <Typography
                gutterBottom
                variant="h6"
                component="div"
                className={'header'}
            >
                Additional Info
            </Typography>

            <Divider className={'divider'}/>

            {/*<div*/}
            {/*    className={'payment-row'}*/}
            {/*>*/}
            {/*        <span*/}
            {/*            className={'payment-row_name'}*/}
            {/*        >*/}
            {/*            Security Deposit*/}
            {/*        </span>*/}
            {/*    <CustomCurrencyInput*/}
            {/*        className={classnames('payment-row_value', {*/}
            {/*            loading: isLoading*/}
            {/*        })}*/}
            {/*        value={dealState.security_deposit}*/}
            {/*        onChange={(value) => setDealStateWithUpdate({security_deposit: value})}*/}
            {/*    />*/}
            {/*</div>*/}

            <div
                className={'payment-row'}
            >
                    <span
                        className={'payment-row_name'}
                    >
                        Applicant Credit Score
                    </span>
                <CustomCurrencyInput
                    className={classnames('payment-row_value', {
                        loading: isLoading
                    })}
                    value={dealState.credit_score}
                    onChange={(value) => setDealStateWithUpdate({credit_score: value})}
                    InputProps={{}}
                />
            </div>
        </Box>
    )
}