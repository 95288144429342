import React, {createContext, useContext, useEffect, useState, useRef} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import {useParams} from "react-router";
import useDidMountEffect from "../hooks/useDidMountEffect";
import {useDebounce, usePrevious} from "@uidotdev/usehooks";
import useEnterEffect from "../hooks/useEnterEffect";
import {useUserContext} from "./UserContext";
import {useModalContext} from "./ModalContext";
import {toast} from "react-toastify";
import QuoteHelper from "../utils/QuoteHelper";


export const PURCHASE_DEAL_LIGHT = '#1BA897'
export const PURCHASE_DEAL_DARK = '#007D6E'
export const LEASE_DEAL_DARK = '#2267CE'
export const LEASE_DEAL_LIGHT = '#2B8EEA'

export const DealContext = createContext(undefined)

const getInitialState = (location) => {
    return {
        msrp: '',
        cost: '',
        description: '',
        invoice: 0,
        above: 0,
        back: 0,
        profit: 0,
        car: null,
        is_purchase: false,
        location: location,
        location_id: location.id,
        drive_off: null,
        term: 36,
        mileage: 10_000,

        address_line_one: location.address_line_one,
        address_line_two: location.address_line_two,
        city: location.city,
        state: location.state,
        zipcode: location.zipcode,
        county: location.county,
    }
}

export const DealContextProvider = ( { children, id, isDeskingContext, ...props } ) => {
    const { profileState } = useUserContext()
    const { setIsDeskingModalOpen } = useModalContext()
    const params = useParams();

    const dealId = () => {
        return id || params.id
    }

    const [dealState, setDealState] = useState(null)

    const dealPageRef = useRef(null)

    const [createDealResponse, createDeal] = useFetchHook(fetcher.createDeal)
    const [cloneDealResponse, cloneDeal] = useFetchHook(fetcher.createDeal)
    const [fetchDealResponse, fetchDeal] = useFetchHook(fetcher.getDeal)
    const [updateDealResponse, updateDeal] = useFetchHook(fetcher.updateDeal)
    const [getDeskingResponse, fetchDesking] = useFetchHook(fetcher.getDesking)

    const debouncedDeal = useDebounce(dealState, 400)

    const prevState = usePrevious(dealState);

    const dealColorDark = () => {
        return dealState.is_purchase ? PURCHASE_DEAL_DARK : LEASE_DEAL_DARK
    }

    const dealColorLight = () => {
        return dealState.is_purchase ? PURCHASE_DEAL_LIGHT : LEASE_DEAL_LIGHT
    }


    const dealColorDarkAlt = () => {
        return dealState.is_purchase ? LEASE_DEAL_DARK : PURCHASE_DEAL_DARK
    }

    const dealColorLightAlt = () => {
        return dealState.is_purchase ? LEASE_DEAL_LIGHT : PURCHASE_DEAL_LIGHT
    }

    const setDealStateWithUpdate = (newState, shouldUpdate = false) => {
        setDealState({...dealState, ...newState, _updateInBackend: shouldUpdate })
    }

    const setDealStateWithoutUpdate = (newState) => {
        setDealState(newState)
    }

    const setDealStateWithoutQuoteUpdate = (newState) => {
        setDealState({...dealState, ...newState, _updateInBackend: true, _no_update: true })
    }


    const updateDealFunc = () => {
        const updateObj = structuredClone(dealState)
        delete updateObj._updateInBackend
        return updateDeal(dealState.id, updateObj)
    }

    const createUpdateFunc = () => {
        const updateObj = structuredClone(dealState)
        delete updateObj._updateInBackend
        if (updateObj.id) {
            return updateDealFunc()
        } else {
            return createDeal(updateObj).then((data) => {
                setIsDeskingModalOpen({id: data.data.id})
            })
        }
    }

    const cloneDealAsPurchaseOrLease = () => {
        const clonedDeal = structuredClone(dealState)
        clonedDeal['is_purchase'] = Boolean(!dealState.is_purchase)
        delete clonedDeal['selected_quote']
        return cloneDeal(clonedDeal)
    }

    const resetDealState = () => {
        setDealState(getInitialState(profileState.default_location))
    }

    useEnterEffect(() => {
        if (dealState.car && dealState.msrp && dealState.cost && !updateDealResponse.loading) {
            createUpdateFunc()
        }
    }, [dealState, updateDealResponse])

    useEffect(function fetchDealUsingID(){
        if (dealId()) {
            if (isDeskingContext) {
                fetchDesking(dealId())
            } else {
                fetchDeal(dealId())
            }
        } else {
            setDealState(getInitialState(profileState.default_location))
        }
    }, [dealId()])

    useEffect(function setFetchedQuote(){
        if (fetchDealResponse?.data?.data) {
            setDealStateWithoutUpdate(fetchDealResponse?.data?.data)
        }
    }, [fetchDealResponse])

    useEffect(function setCreatedQuote(){
        if (createDealResponse?.data?.data) {
            setDealStateWithoutUpdate(createDealResponse?.data?.data)
        }
    }, [createDealResponse])

    useDidMountEffect(function updateQuoteInBackend(){
        if (dealState?._updateInBackend === true) {
            updateDealFunc()
        }
    }, [debouncedDeal])

    useEffect(function returnToPreviousState() {
        if (updateDealResponse?.error && !updateDealResponse.loading) {
            setDealStateWithoutUpdate(prevState)
        }
    }, [updateDealResponse])

    // If transactionId of selected_quote is new, use that data
    useDidMountEffect(function updateQuoteFromBackend(){
        if (updateDealResponse?.data?.data && !updateDealResponse.loading && !updateDealResponse.error) {
            const newDealState = updateDealResponse?.data?.data
            setDealStateWithoutUpdate(newDealState)

            const helper = new QuoteHelper(updateDealResponse?.data?.data)
            const expirationDate = helper.expirationDate()

            if (new Date(expirationDate) <  Date()) {
                toast.warn("This program is expired.")
            } else {
                toast.success("Successfully updated deal.")
            }
        }
    }, [updateDealResponse])

    const value = {
        dealPageRef,
        dealState,

        createDeal,
        createDealResponse,

        updateDeal,
        updateDealResponse,

        fetchDeal,
        fetchDealResponse,

        cloneDeal,
        cloneDealResponse,

        dealId,
        setDealStateWithUpdate,
        setDealStateWithoutUpdate,
        setDealStateWithoutQuoteUpdate,

        dealColorDark,
        dealColorLight,
        dealColorDarkAlt,
        dealColorLightAlt,

        cloneDealAsPurchaseOrLease,

        createUpdateFunc,
        resetDealState,
        ...props
    }

    if (!isDeskingContext) {
        if (!dealState) return null;

        if (dealId() && !dealState.id) return null;
    }

    return (
        <DealContext.Provider value={value}>
            { children }
        </DealContext.Provider>
    )
}

export class NoDealContextError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NoDealContextError';
    }
}

export const useDealContext = () => {
    const context = useContext(DealContext)

    if (context === undefined) {
        throw new NoDealContextError('useDealContext must be used within DealContext')
    }
    return context
}