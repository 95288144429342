import React from "react";
import DeskingHelper from "../../utils/DeskingHelper";
import {DeskingTableHeaders} from "./DeskingTableHeaders";
import {DeskingTableRow} from "./DeskingTableRow";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Typography from "@mui/material/Typography";

export const DeskingTable = ( { dealState, dealId } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const quotes = dealState.quotes
    const deskingHelper = new DeskingHelper(quotes)

    const quotesByTermAndLender = deskingHelper.quotesByTermAndLender()
    const terms = deskingHelper.terms()
    const lenders = deskingHelper.lenders()

    return (
        <div className={'desking-scroll-container'}>
            <table>
                <DeskingTableHeaders headers={lenders} />
                <tbody>
                {
                    terms.map( (term, index) => {
                        const quotes = quotesByTermAndLender[term]

                        return <DeskingTableRow
                            key={index}
                            dealState={dealState}
                            term={term}
                            quotes={quotes}
                            lenders={lenders}
                            dealId={dealId}
                        />
                    })
                }
                </tbody>
            </table>
        </div>
    )
}