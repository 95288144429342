import {useDealContext} from "../../contexts/DealContext";
import React, {useState} from "react";
import IncentiveHelper from "../../utils/IncentiveHelper";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {DealRebateModal} from "./DealRebateModal";
import CurrencyInput from "react-currency-input-field";
import Checkbox from "@mui/material/Checkbox";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useMediaQuery} from "react-responsive";
import QuoteHelper from "../../utils/QuoteHelper";
import useFeeStateHook from "../../hooks/useFeeStateHook";

export const DealPageRebateItem = ({
   incentive,
   isSelected,
   dealState,
   setDealStateWithUpdate
}) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const {updateDealResponse} = useDealContext()
    const [selected, setSelected] = useState(isSelected)
    const [isRebateModalOpen, setIsRebateModalOpen] = useState(false)
    const incentiveHelper = new IncentiveHelper(incentive)

    const helper = new QuoteHelper(dealState)
    const rebateFee = helper.findDealerRebateFee(incentiveHelper.feeId())

    const [feeState, setFeeStateWithUpdate] = useFeeStateHook(rebateFee)

    const applyRebate = () => {
        let newDealState = structuredClone(dealState)
        let allFees = newDealState.selected_quote['Quote']['Fees']

        allFees = allFees.filter((fee) => fee['Id'] !== incentiveHelper.feeId())
        if (selected) {
            allFees = incentiveHelper.setIncentive(allFees)
        } else {
            allFees = incentiveHelper.removeIncentive(allFees)
        }

        newDealState.selected_quote['Quote']['Fees'] = allFees
        setDealStateWithUpdate(newDealState, true)
    }

    useDidMountEffect(() => {
        applyRebate()
    }, [selected])

    const isLoading = updateDealResponse?.loading

    const isDealerRebateFee = incentive['Id'] === '10011'

    const isTaxed = Boolean(
        selected &&
        rebateFee &&
        rebateFee?.TaxIndex &&
        rebateFee?.TaxIndex !== 0
    )

    return (
        <TableRow key={incentiveHelper.feeId()}>
            <TableCell
                align="left"
                width={isTabletOrMobile ? '10%' : '40%'}
            >
                {incentiveHelper.name()}
                {
                    !isTabletOrMobile &&
                    <Tooltip title="Details" arrow placement={'top'}>
                        <DescriptionOutlinedIcon
                            className={'DescriptionIcon'}
                            onClick={() => setIsRebateModalOpen(!isRebateModalOpen)}
                        />
                    </Tooltip>
                }
                {
                    isRebateModalOpen &&
                    <DealRebateModal
                        isOpen={isRebateModalOpen}
                        setIsOpen={setIsRebateModalOpen}
                        name={incentiveHelper.name()}
                        description={incentiveHelper.description()}
                    />
                }
            </TableCell>
            <TableCell
                align="right"
                width={isTabletOrMobile ? '10%' : '40%'}
            >
                <CurrencyInput
                    className={'payment-row_value disabled'}
                    prefix="$"
                    value={isDealerRebateFee ? incentive['Amount'] * -1 : incentiveHelper.amount()}
                    disabled={true}
                />
            </TableCell>
            <TableCell align="right">
                <Checkbox
                    className={'payment-row_value'}
                    onChange={() => setSelected(!selected)}
                    checked={selected}
                    disabled={isLoading}
                />
            </TableCell>
            <TableCell align="right">
                <Checkbox
                    className={'payment-row_value'}
                    onChange={() => {
                        const newTaxIndex = feeState?.TaxIndex === 0 ? -1 : 0
                        setFeeStateWithUpdate({...feeState, TaxIndex: newTaxIndex})
                    }}
                    checked={isTaxed}
                    disabled={!selected || isLoading || !feeState}
                />
            </TableCell>
        </TableRow>
    )
}