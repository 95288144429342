import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {LEASE_DEAL_LIGHT} from "../../contexts/DealContext";
import {useModalContext} from "../../contexts/ModalContext";
import {useCalcContext} from "../../contexts/CalcContext";
import Radio from "@mui/material/Radio";
import {useUserContext} from "../../contexts/UserContext";
import LocationHelper from "../../utils/LocationHelper";
import PushPinIcon from '@mui/icons-material/PushPin';
import {LocationItem} from "../../components/LocationItem";


const SelectLocationItem = (props) => {
    const {
        location,
        selectedLocation,
        handleLocationChange
    } = props

    return (
        <Typography
            sx={{
                fontSize: '16px',
                marginTop: '10px',
                color: '#666'
            }}
        >
            <Radio
                checked={location.id === selectedLocation.id}
                onChange={() => handleLocationChange(location)}
                name={new LocationHelper(location).name()}
                value={location}

            />
            <span>{location.city}, </span>
            <span>{location.county}, </span>
            <span>{location.state}</span>

        </Typography>
    )
}


export const CalcPageSelectLocation = () => {
    const { profileState } = useUserContext()
    const { setIsLocationModalOpen } = useModalContext()

    const {
        calcState,
        setCalcState,
    } = useCalcContext()

    const handleLocationChange = (newLocation) => {
        setCalcState({
            ...calcState,
            location: newLocation,
            location_id: newLocation.id,

            address_line_one: newLocation.address_line_one,
            address_line_two: newLocation.address_line_two,
            city: newLocation.city,
            state: newLocation.state,
            zipcode: newLocation.zipcode,
            county: newLocation.county,
        })
    }

    const defaultLocation = profileState.default_location
    const selectedLocation = calcState.location

    const displayedLocationIds = [...profileState.pinned_locations, defaultLocation].map(l => l.id)

    const shouldDisplaySelectedLocation = !displayedLocationIds.includes(selectedLocation.id)

    return (
        <React.Fragment>
            <Typography
                gutterBottom
                variant="h6"
                component="div"
                className={'header location-info'}
                sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                }}
            >
                <span>02 Choose Location</span>
            </Typography>
            <Divider className={'divider'}/>

            <SelectLocationItem
                location={defaultLocation}
                selectedLocation={selectedLocation}
                handleLocationChange={() => handleLocationChange(defaultLocation)}
            />
            <Divider className={'divider'}/>
            {
                shouldDisplaySelectedLocation &&
                    <SelectLocationItem
                        location={selectedLocation}
                        selectedLocation={selectedLocation}
                        handleLocationChange={(location) => handleLocationChange(location)}
                    />
            }
            <div className={'Pinned-locations-container'}>
                {
                    profileState?.pinned_locations?.map(
                        (location) => {
                            return (
                                <SelectLocationItem
                                    key={location.id}
                                    location={location}
                                    selectedLocation={selectedLocation}
                                    handleLocationChange={(location) => handleLocationChange(location)}
                                />
                            )
                        }
                    )
                }
            </div>

            <Button
                className={'dealPage-button'}
                style={{ background: LEASE_DEAL_LIGHT }}
                onClick={setIsLocationModalOpen}
            >
                Edit Location
            </Button>
        </React.Fragment>
    )
}