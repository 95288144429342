import React, {createContext, useContext, useEffect, useState} from 'react';

import fetcher from "../fetcher";
import {useFetchHook} from "../hooks/useFetchHook";
import useDidMountEffect from "../hooks/useDidMountEffect";
import {useDebounce} from "@uidotdev/usehooks";

export const UserContext = createContext(undefined)

export const UserContextProvider = ( { children } ) => {
    const [fetchProfileResponse, fetchProfile] = useFetchHook(fetcher.getProfile)
    const [updateProfileResponse, updateProfile] = useFetchHook(fetcher.updateProfile)

    const [getLocationsResponse, getLocations] = useFetchHook(fetcher.getLocations)
    const [createLocationResponse, createLocation] = useFetchHook(fetcher.createLocation)
    const [updateLocationResponse, updateLocation] = useFetchHook(fetcher.updateLocation)
    const [deleteLocationResponse, deleteLocation] = useFetchHook(fetcher.deleteLocation)

    const [changePasswordResponse, changePassword] = useFetchHook(fetcher.changePassword)

    const [profileState, setProfileState] = useState(null)
    const debouncedProfile = useDebounce(profileState, 400)


    const setProfileStateWithUpdate = (newState) => {
        setProfileState({...profileState, ...newState, _updateInBackend: true })
    }

    const setProfileDealStateWithoutUpdate = (newState) => {
        setProfileState({...profileState, ...newState, _updateInBackend: false })
    }

    const updateProfileFunc = () => {
        const updateObj = structuredClone(profileState)
        delete updateObj._updateInBackend
        return updateProfile(updateObj)
    }

    useEffect(function fetchProfileFromBackend() {
        fetchProfile()
    }, [])

    useEffect(function setFetchedProfile(){
        if (fetchProfileResponse?.data?.data) {
            setProfileDealStateWithoutUpdate(fetchProfileResponse?.data?.data)
        }
    }, [fetchProfileResponse])

    useDidMountEffect(function updateProfileInBackend(){
        if (profileState?._updateInBackend === true) {
            updateProfileFunc()
        }
    }, [debouncedProfile])

    // If transactionId of selected_quote is new, use that data
    useDidMountEffect(function updateProfileFromBackend(){
        if (updateProfileResponse?.data?.data && !updateProfileResponse.loading) {
            const updateObj = structuredClone(updateProfileResponse?.data?.data)
            delete updateObj._updateInBackend
            setProfileDealStateWithoutUpdate(updateObj)
        }
    }, [updateProfileResponse])

    useEffect(() => {
        return () => {
            setProfileState(null)
        }
    }, [])

    const refreshData = () => {
        setProfileState(null)
        fetchProfile()
    }

    const isSuperUser = () => {
        return profileState.is_superuser === 'True'
    }

    const value = {
        refreshData,
        profileState,
        setProfileState,

        updateProfile,
        updateProfileResponse,

        getLocations,
        getLocationsResponse,

        createLocation,
        createLocationResponse,

        updateLocation,
        updateLocationResponse,

        deleteLocation,
        deleteLocationResponse,

        setProfileStateWithUpdate,
        setProfileDealStateWithoutUpdate,

        changePassword,
        changePasswordResponse,

        isSuperUser
    }

    if (!profileState) return null;

    return (
        <UserContext.Provider value={value}>
            { children }
        </UserContext.Provider>
    )
}

export const useUserContext = () => {
    const context = useContext(UserContext)

    if (context === undefined) {
        throw new Error('useUserContext must be used within UserContext')
    }
    return context
}