import React, {createContext, useContext, useState} from 'react';
import {useFetchHook} from "../hooks/useFetchHook";
import fetcher from "../fetcher";
import useDidMountEffect from "../hooks/useDidMountEffect";
import _ from 'lodash'
import {useUserContext} from "./UserContext";

export const CalcContext = createContext(undefined)

const initialCarState = {
    makes: [],
    models: [],
    trims: [],

    year: '',
    make: '',
    model: '',
    trim: '',
    style_description: '',
}

const getInitialCalcState = (location) => ({
    msrp: '',
    cost: '',
    car: null,
    is_purchase: false,
    location: location,
    location_id: location.id,

    address_line_one: location.address_line_one,
    address_line_two: location.address_line_two,
    city: location.city,
    state: location.state,
    zipcode: location.zipcode,
    county: location.county,
})

export const CalcContextProvider = ({ children, id } ) => {
    const { profileState } = useUserContext()

    const [carState, setCarState] = useState(initialCarState)
    const [calcState, setCalcState] = useState(getInitialCalcState(profileState.default_location))

    const [getMakesForYearResponse, getMakesForYear] = useFetchHook(fetcher.makesForYear)
    const [getModelsForMakeResponse, getModelsForMake] = useFetchHook(fetcher.modelsForMake)
    const [getTrimsForModelResponse, getTrimsForModel] = useFetchHook(fetcher.trimsForModel)
    const [getCarResponse, getCar] = useFetchHook(fetcher.getCar)

    const [createDealResponse, createDeal] = useFetchHook(fetcher.createDeal)

    const resetCarState = () => {
        setCarState(initialCarState)
    }

    const resetCalcState = () => {
        setCarState(initialCarState)
        setCalcState(getInitialCalcState(profileState.default_location))
    }

    useDidMountEffect(function getMakesForYearFromBE(){
        if (carState.year) {
            getMakesForYear(carState.year)
        }
    }, [carState.year])

    useDidMountEffect(function getModelsForMakeFromBE(){
        if (carState.make) {
            getModelsForMake(carState.year, carState.make)
        }
    }, [carState.make])

    useDidMountEffect(function getTrimsForModelFromBE(){
        if (carState.model) {
            getTrimsForModel(carState.year, carState.make, carState.model)
        }
    }, [carState.model])

    useDidMountEffect(function getCarFromBE(){
        if (carState.trim && carState.style_description) {
            const data = {
                year: carState.year,
                make: carState.make,
                model: carState.model,
                trim: carState.trim.Trim,
                style_description: carState.style_description,
                vin_override: carState.trim.VINOverride
            }
            getCar(data)
        }
    }, [carState.style_description, carState.trim])

    useDidMountEffect(function setMakes() {
        if (!getMakesForYearResponse?.loading && getMakesForYearResponse?.data?.data) {
            const makes = _.sortBy(getMakesForYearResponse?.data?.data?.VehicleMakes, ['Make'])
            setCarState({
                ...carState,
                makes: makes,
            })
        }
    }, [getMakesForYearResponse])

    useDidMountEffect(function setModels(){
        if (!getModelsForMakeResponse?.loading && getModelsForMakeResponse?.data?.data) {
            const models = getModelsForMakeResponse?.data?.data?.VehicleModels
            const uniqModels = _.uniqBy(models, (t) => (t['Model']))
            const sortedUniqModels = _.sortBy(uniqModels, ['Model'])
            setCarState({
                ...carState,
                models: sortedUniqModels,
            })
        }
    }, [getModelsForMakeResponse])

    useDidMountEffect(function setTrims(){
        if (!getTrimsForModelResponse?.loading && getTrimsForModelResponse?.data?.data) {
            const trims = getTrimsForModelResponse?.data?.data?.VehicleStyles
            const uniqTrims = _.uniqBy(trims, (t) => (t['StyleDescription']))
            const sortedUniqTrims = _.sortBy(uniqTrims, ['Trim'])
            setCarState({
                ...carState,
                trims: sortedUniqTrims,
            })
        }
    }, [getTrimsForModelResponse])

    useDidMountEffect(function setCar(){
        if (!getCarResponse?.loading && getCarResponse?.data?.data) {
            const data = getCarResponse?.data?.data
            const last_deal_data = data?.last_deal_data
            setCalcState({
                ...calcState,
                car: data?.id,
                msrp: last_deal_data?.msrp,
                cost: last_deal_data?.cost,
                profit: last_deal_data?.profit,
            })
        }
    }, [getCarResponse])

    const value = {
        carState,
        setCarState,

        calcState,
        setCalcState,

        getMakesForYear,
        getMakesForYearResponse,

        getModelsForMake,
        getModelsForMakeResponse,

        getTrimsForModel,
        getTrimsForModelResponse,

        resetCarState,
        resetCalcState,

        createDeal,
        createDealResponse
    }

    if (!carState) return null;

    return (
        <CalcContext.Provider value={value}>
            { children }
        </CalcContext.Provider>
    )
}

export const useCalcContext = () => {
    const context = useContext(CalcContext)

    if (context === undefined) {
        throw new Error('useCalcContext must be used within CalcContext')
    }
    return context
}