import React, {useEffect, useState} from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

import {usePricingContext} from "../../contexts/PricingContext";
import Tooltip from "@mui/material/Tooltip";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {useModalContext} from "../../contexts/ModalContext";
import classnames from "classnames";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import useEnterEffect from "../../hooks/useEnterEffect";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {LocationModal} from "../DealPage/LocationModal";
import {useUserContext} from "../../contexts/UserContext";
import {PopoverInput} from "../../components/PopoverInput";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";


const getInitialState = (pricing, style_description, location) => {
    return {
        description: pricing?.description || '',
        msrp: pricing?.msrp || '',
        invoice: pricing?.invoice || null,
        above: pricing?.above || 0,
        back: pricing?.back || 0,
        profit: pricing?.profit || 0,
        car: style_description.car,
        is_purchase: false,
        pricing_id: pricing?.id,

        address_line_one: location.address_line_one,
        address_line_two: location.address_line_two,
        city: location.city,
        state: location.state,
        zipcode: location.zipcode,
        county: location.county,
    }
}

export const PricingListRow = ( { pricing, style_description } ) => {
    const { profileState } = useUserContext()
    const default_location = profileState?.default_location
    const [dealLocation, setDealLocation] = useState(default_location)
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)

    const [pricingState, setPricingState] = useState(getInitialState(pricing, style_description, default_location))
    const [isCreatingLease, setIsCreatingLease] = useState(false)
    const [isCreatingPurchase, setIsCreatingPurchase] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    const {
        deletePricing,
        createDeal,
        createDealResponse,
        createPricing,
        createPricingResponse,
        updatePricing,
        refreshData
    } = usePricingContext()
    const { setIsDeskingModalOpen, setIsDealModalOpen, isDealModalOpen } = useModalContext()

    const updatePricingState = (field, value) => {
        setPricingState({...pricingState, [field]: value})
    }

    const deletePricingRow = (pricingId) => {
        setIsDeleting(true)
        return deletePricing(pricingId).then(() => {
            setIsDeleting(false)
            refreshData()
        }).catch(() => {
            setIsDeleting(false)
        })
    }

    const hasPricingChanged = () => {
        return (
            pricingState.description !== pricing.description ||
            pricingState.msrp !== pricing.msrp ||
            pricingState.invoice !== pricing.invoice ||
            pricingState.above !== pricing.above ||
            pricingState.back !== pricing.back ||
            pricingState.profit !== pricing.profit
        )
    }

    const updatePricingFunc = (isPurchase) => {
        const nextFunc = isPurchase ? createNewPurchase : createNewLease
        if (hasPricingChanged()) {
            setIsUpdating(true)
            let updateData = pricingState
            updateData.id = pricing.id
            return updatePricing(pricing.id, updateData).then((data) => {
                setIsUpdating(false)
                const pricingId = data.data.id
                return nextFunc(pricingId)
            }).catch(() => {
                setIsUpdating(false)
            })
        } else {
            return nextFunc(pricing.id)
        }
    }

    const createNewPricing = (isPurchase) => {
        const nextFunc = isPurchase ? createNewPurchase : createNewLease
        return createPricing(pricingState).then((data) => {
            const pricingId = data.data.id
            return nextFunc(pricingId).then(() => {
                setPricingState(
                    getInitialState(null, style_description, default_location)
                )
            })
        })
    }

    const createNewLease = (pricingId) => {
        setIsCreatingLease(true)
        let createData = structuredClone(pricingState)
        createData['pricing_id'] = pricingId
        createData['location_id'] = dealLocation.id

        return createDeal(createData).then((data) => {
            setIsDeskingModalOpen(
                {
                    id: data.data.id,
                    onSave: (updateData) =>  setIsDealModalOpen(updateData.id),
                    isDeskingContext: false,
                }
            )
            refreshData()
            setIsCreatingLease(false)
        }).catch(() => setIsCreatingLease(false))
    }

    const createNewPurchase = (pricingId) => {
        setIsCreatingPurchase(true)
        let createData = structuredClone(pricingState)

        createData['is_purchase'] = true
        createData['pricing_id'] = pricingId
        createData['location_id'] = dealLocation.id

        return createDeal(createData).then((data) => {
            setIsDeskingModalOpen(
                {
                    id: data.data.id,
                    onSave: (updateData) =>  setIsDealModalOpen(updateData.id),
                    isDeskingContext: false,
                }
            )
            refreshData()
            setIsCreatingPurchase(false)
        }).catch(() => setIsCreatingPurchase(false))
    }

    const buildId = (field) => {
        return pricing ? `PricingListRow-${field}-${pricing.id}` : `PricingListRow-create-${style_description.id}-${field}`
    }

    const isLoading = createDealResponse?.loading || createPricingResponse?.loading
    const isDisabled = Boolean(!pricingState.msrp || isLoading)

    useEnterEffect(() => {
        const docId = document.activeElement.id
        if (!isDisabled) {
            if (docId.split('-')[2] === (String(pricing?.id)) ) {
                updatePricingFunc(false)
            }
            if (
                docId.split('-')[1] === 'create' &&
                docId.split('-')[2] === String(style_description.id) &&
                Boolean(!pricing)
            ) {
                createNewPricing(false)
            }
        }
    }, [pricingState])

    const isDefaultLocation = Boolean(dealLocation.id === default_location.id)
    const isCreating = isCreatingLease || isCreatingPurchase

    return (
        <React.Fragment key={style_description?.id || `${style_description.name}-pricing-new`}>
            {
                pricing?.id &&
                <td className={'delete-pricing-row-icon'}>
                    <Tooltip title="Delete" arrow placement={'top'}>
                        <DeleteSharpIcon
                            className={classnames(
                                'action-icon garbage', {
                                    loader: isDeleting
                                }
                            )}
                            onClick={() => deletePricingRow(pricing.id)}
                        />
                    </Tooltip>
                </td>
            }
            <td className={'pricing-list-row_description'}>
                <PopoverInput
                    id={buildId('description')}
                    value={pricingState.description}
                    onChange={text => updatePricingState('description' , text)}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('msrp')}
                    value={pricingState.msrp}
                    onChange={(value) => updatePricingState('msrp', value)}
                    prefix="$"
                    placeholder={'0'}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('invoice')}
                    prefix="$"
                    value={pricingState.invoice}
                    onChange={(value) => updatePricingState('invoice', value)}
                    allowNull={true}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('above')}
                    prefix="$"
                    value={pricingState.above}
                    defaultValue={0}
                    onChange={(value) => updatePricingState('above', value)}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('back')}
                    prefix="$"
                    value={pricingState.back}
                    defaultValue={0}
                    onChange={(value) => updatePricingState('back', value)}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('profit')}
                    prefix="$"
                    value={pricingState.profit}
                    defaultValue={0}
                    onChange={(value) => updatePricingState('profit', value)}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <Tooltip title={'Lease'} arrow placement={'top'}>
                    <AddOutlinedIcon
                        className={classnames(
                            'action-icon', {
                                loader: isCreatingLease,
                                disabled: isDisabled
                            }
                        )}
                        onClick={() => {
                            if (isLoading) return;
                            if (pricing?.id) {
                                updatePricingFunc(false)
                            } else {
                                createNewPricing(false)
                            }
                        }}
                    />
                </Tooltip>
                <Tooltip title={'Purchase'} arrow placement={'top'}>
                    <AccountBalanceIcon
                        className={classnames(
                            'action-icon purchase', {
                                loader: isCreatingPurchase,
                                disabled: isDisabled
                            }
                        )}
                        onClick={() => {
                            if (isLoading) return;
                            if (pricing?.id) {
                                updatePricingFunc(true)
                            } else {
                                createNewPricing(true)
                            }
                        }}
                    />
                </Tooltip>

                <Tooltip
                    title={`Edit Location (${dealLocation.city} ${dealLocation.county} ${dealLocation.state})`}
                    arrow placement={'top'}
                >
                    {
                        isDefaultLocation ?
                            <LocationOnIcon
                                className={'action-icon'}
                                onClick={() => setIsLocationModalOpen(true)}
                            /> :
                            <EditLocationIcon
                                className={'action-icon'}
                                onClick={() => setIsLocationModalOpen(true)}
                            />
                    }
                </Tooltip>
                {
                    // Existing Pricing Section
                    pricing?.id &&
                    <Tooltip title={'Deals'} arrow placement={'top'}>
                        <Link to={`/deals?pricing_id=${pricing.id}`} target="_blank">
                            <LibraryBooksIcon
                                className={'action-icon'}
                            />
                        </Link>
                    </Tooltip>

                }
            </td>

            {
                isLocationModalOpen &&
                <LocationModal
                    open={isLocationModalOpen}
                    initialLocation={dealLocation}
                    onClose={() => setIsLocationModalOpen(false)}
                    selectLocation={(location) => {
                        setDealLocation(location)
                        setIsLocationModalOpen(false)

                        setPricingState({
                            ...pricingState,

                            address_line_one: location.address_line_one,
                            address_line_two: location.address_line_two,
                            city: location.city,
                            state: location.state,
                            zipcode: location.zipcode,
                            county: location.county,
                        })
                      }
                    }
                    saveButtonText={'Set Deal Location'}
                />
            }
        </React.Fragment>
    )
}