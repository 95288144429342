import _ from 'lodash'

class QuoteHelper {
    constructor(deal, quote) {
        this.deal = deal
        this.quote = quote || this.deal.selected_quote
    }

    get = (path, base) => _.get(base || this.quote, path)

    quoteKey = () => this.get(['Quote'])

    fees = () => this.get(['Fees'], this.quoteKey()) || []

    findFee = (feeId) => this.fees().find(fee => fee['TitleId'] === feeId)

    findFeeAmountOrZero = (feeId) => {
        const fee = this.findFee(feeId)
        return fee ? fee['Amount'] : 0
    }

    subtitleFeeIds = () => {
        let subtitleFeeIds = []
        const fees = this.fees()
        _.map(fees, (fee) => {
            if (fee['SubTitleId'] && fee['SubTitleId'] !== null) {
                subtitleFeeIds.push(fee['SubTitleId'])
            }
        })
        return subtitleFeeIds
    }

    feeNames = () => this.fees().map(f => f.Name)

    terminationFee = () => {
        return this.fees().find((fee) => fee['TitleId'] === '1116')
    }

    dispositionFee = () => {
        return this.fees().find((fee) => fee['TitleId'] === '1105')
    }

    // These are custom rebates that the user creates. There are Dealer rebates from provider, they won't have Id of 10011
    dealerRebateFees = () => {
        return this.fees().filter((fee) => {
            return (
                fee['TitleId'] === '10011' &&
                fee['Amount'] !== 0 &&
                fee['Id'] === '10011'
            )
        })
    }

    customerRebateFees = () => {
        return this.fees().filter((fee) => fee['TitleId'] === '10012')
    }

    totalCustomerRebateFeesAmount = () => {
        let total = 0
        this.customerRebateFees().map((fee) => total += fee.Amount)
        return total
    }

    feesWithAmount = () => this.fees().filter((fee) => {
        if (fee.Amount === 0) return false;
        if (fee.Type === 'PaidOutsideOfClosing') return false;  // PaidOutsideOfClosing means its not part of the calculations
        if (fee.TitleId === '10013') return false;  // Custom Drive off Fee shouldn't be included
        if (fee.TitleId === '10012') return false;  // This TitleID is for Rebates
        if (fee.DescriptionType === 'Rebate') return false;  // No Rebates
        return true
    })

    registrationFees = () => this.feesWithAmount().filter((fee) => {
        if (fee.Category === 'Registration') return true;
        if (fee.TitleId === '10019') return true;  // Non Taxable Registration fee
        if (fee.TitleId === '10024') return true;  // Taxable Registration fee
        return false
    })

    nonRegistrationFees = () => this.feesWithAmount().filter((fee) => {
        if (fee.Category === 'Registration') return false;
        if (fee.TitleId === '10019') return false;  // Non Taxable Registration fee
        if (fee.TitleId === '10024') return false;  // Taxable Registration fee
        return true
    })

    nonRegistrationFeesWithoutAcqAndDocFee = () => this.nonRegistrationFees().filter((fee) => {
        if (fee.TitleId === '1088') return false;  // Acquisition Fee
        if (fee.TitleId === '1001') return false;  // Doc Fee
        return true
    })

    registrationFeeOverride = () => this.fees().find((fee) => fee.TitleId === "10019")

    totalRegistrationFees = () => {
        let total = 0
        this.registrationFees().map((fee) => total += Number(fee.Amount))
        return total
    }

    dealerFees = () => this.feesWithAmount().filter((fee) => fee.Category === 'Dealer')

    lenderFees = () => {
        const acquisitionFee = this.findFee("1088")

        // Remove Acquisition Fee
        const lenderFees = this.feesWithAmount().filter((fee) => fee.Category === 'Lender').filter(fee => fee['TitleId'] !== "1088")

        // manually add back Acquisition Fee
        if (acquisitionFee) {
            lenderFees.push(acquisitionFee)
        }

        return lenderFees
    }

    otherFees = () => this.feesWithAmount().filter((fee) => !['Registration', 'Dealer', 'Lender'].includes(fee.Category) )

    isAllFinanced = () => !Boolean(this.feesWithAmount().some((fee) => fee['Type'] === 'Upfront'))

    totalFeeAmount = () => {
        let total = 0
        const registrationFeeOverride = this.registrationFeeOverride()
        if (registrationFeeOverride && registrationFeeOverride['Amount'] !== null) {
            this.nonRegistrationFees().map((fee) => total += Number(fee.Amount))
            total += Number(registrationFeeOverride.Amount)
        } else {
            this.feesWithAmount().map((fee) => total += Number(fee.Amount))
        }

        return _.round(total, 2)
    }

    sumFees = (fees) => {
        let total = 0
        fees.map((fee) => total += Number(fee.Amount))
        return total
    }

    selectedDeskingOptions = () => this.get(['SelectedDeskingOptions'])

    term = () => _.find(this.selectedDeskingOptions(), (o) => o.Parameter === 'Term')?.Values

    lender = () => this.get(['OfferDetails', 'Lender', 'Name'])

    lenderCode = () => this.get(['OfferDetails', 'Lender', 'Code'])

    basePayment = () => this.get(['BasePayment'], this.quoteKey())

    financeCharge = () => this.get(['FinanceCharge'], this.quoteKey())

    quoteCash = () => this.get(['Cash'], this.quoteKey())

    payment = () => this.get(['Payment'], this.quoteKey())

    advancePayment = () => this.get(['AdvancePayment'], this.quoteKey())

    purchaseOption = () => this.get(['PurchaseOption'], this.quoteKey())

    balloon = () => this.get(['Balloon'], this.quoteKey())

    loanTerm = () => this.get(['LoanTerm'], this.quoteKey())

    daysTo1stPmt = () => this.get(['DaysTo1stPmt'], this.quoteKey())

    annualMileage = () => this.get(['QuoteParameters', 'AnnualMileage'])

    mileage = () => this.get(['QuoteParameters', 'Mileage'])

    excessMileage = () => this.get(['QuoteParameters', 'NewMileageChargePostPaid'])

    mileageCharge = () => this.get(['QuoteParameters', 'MileageCharge'])

    moneyFactor = () => this.get(['QuoteParameters', 'MoneyFactor'])

    msrp = () => this.get(['QuoteParameters', 'MSRP'])

    baseMSRP = () => this.get(['FeeAndSalesTaxParameters', 'BaseMSRP'])

    residual = () => this.get(['OfferDetails', 'ResidualPercentage'])

    residualPercentage = () => this.get(['QuoteParameters', 'ResidualPercentage'])

    offerDetailsId = () => this.get(['OfferDetails', 'ID'])

    amountDueAtSigning = () => this.get(['Quote', 'AmountDueAtSigning'])

    amountDueOnDelivery = () => this.get(['Quote', 'AmountDueOnDelivery'])

    regularPayment = () => this.get(['Quote', 'RegularPayment'])

    transactionId = () => this.get(['TransactionId'])

    incentives = () => this.get(['OfferDetails', 'Incentives'])

    findDealerRebateFee = (feeId) => {
        return this.fees().find((fee) => {
            return (fee['Id'] === feeId) || (fee['SubTitleId'] === feeId)
        })
    }

    isRebateApplied = (feeId) => {
        return this.fees().some((fee) => {
            return Boolean(
                fee['Id'] === feeId &&
                fee['Amount'] !== 0
            )
        })
    }

    acquisitionFee = () => this.findFee("1088")

    docFee = () => this.findFee("1001")

    driveOffFee = () => this.fees().find((fee) => fee['TitleId'] === "10013")

    driveOffFeeTaxed = () => this.fees().find((fee) => {
        return (
            fee['TitleId'] === "10013" &&
            fee.Amount !== 0 &&
            (fee.UpfrontTax || fee.CapitalizedTax)
        )
    })

    driveOffFeeAmount = () => this.findFeeAmountOrZero("10013")

    tradeAsDriveOffFeeAmount = () => this.findFeeAmountOrZero("10015")

    totalDownPayment = () => this.cashDueOnDelivery() + this.totalRebatesAmount()

    salesSubtotal = () => this.quoteCash() - this.totalDownPayment()

    rebateAsDriveOffAmount = () => (
        (this.findFeeAmountOrZero("10015") + this.findFeeAmountOrZero("10023")) -
        (this.findFeeAmountOrZero("10014") + this.findFeeAmountOrZero("10022"))
    )

    inceptionFeesAddedToCap = () => this.get(['CapitalizedUpfrontCosts'], this.quoteKey())

    acquisitionFeeAmount = () => this.findFeeAmountOrZero("1088")

    // Fees minus Aquisition Fee and Down Payment
    feesAndInsurance = () => _.round(this.totalFeeAmount() - this.acquisitionFeeAmount(), 2)

    taxes = () => {
        return this.get(['Taxes'])
    }

    upfrontTaxes = () => this.get(['TotalUpfrontTaxes'], this.quoteKey())

    capReductionCash = () => this.get(['CashDownCCR'], this.quoteKey())

    totalInceptionFees = () => {
        return _.round(
                this.advancePayment() +
                this.acquisitionFeeAmount() +
                this.feesAndInsurance() +
                this.upfrontTaxes() +
                this.capReductionCash()
        , 2)
    }

    tradeAsCapReduction = () => this.get(['TradeCCR'], this.quoteKey())

    rebateAsCapReduction = () => this.get(['RebateCCR'], this.quoteKey()) * -1  // this num should be a negative

    negativeTradeEquity = () => this.get(['NegativeTradeCC'], this.quoteKey())

    totalDriveOff = () => this.get(['TotalAmountAtDriveOff'], this.quoteKey())

    monthlyTax = () => this.advancePayment() - this.basePayment()

    monthlyPayment = () => this.get(['MonthlyPayment'], this.quoteKey())

    finalPayment = () => this.advancePayment()

    expirationDate = () => this.get(['OfferDetails', 'ExpirationDate'])

    quoteRate = () => this.get(['Quote', 'Rate'])

    buyRate = () => this.get(['OfferDetails', 'BuyRate'])

    dealerReserveAmount = () => this.get(['DealerReserveDetails', 'DealerAmount'])

    reserveProfit = () => this.get(['OfferDetails', 'BuyRateAdjustment'])

    mrm = () => this.get(['OfferDetails', 'MRM']) || 0

    purchaseOption = () => this.get(['Quote', 'PurchaseOption'])

    vehicleProfit = () => this.deal.profit

    sellingPrice = () => this.deal.selling_price

    capitalizedCost = () => {
        return this.sellingPrice() +
            this.acquisitionFeeAmount() +
            this.feesAndInsurance() +
            this.inceptionFeesAddedToCap() +
            this.capReductionCash() +
            this.tradeAsCapReduction() +
            this.negativeTradeEquity() +
            this.rebateAsCapReduction()
    }

    cappedTaxes = () =>  this.get(['Quote', 'TotalFinancedTaxes'])

    totalCapitalCost = () => this.capitalizedCost() + this.cappedTaxes()

    apr = () => _.round(this.get(['Quote', 'APR']), 2)

    grossTradeAmount = () => this.findFeeAmountOrZero("10015") + this.findFeeAmountOrZero("10023")

    owedTradeAmount = () => this.findFeeAmountOrZero("10014") + this.findFeeAmountOrZero("10022")

    tradeEquityAmount = () => 0 // until we find out what this is

    trade = () => this.grossTradeAmount() - this.owedTradeAmount() - this.tradeEquityAmount()

    totalProfit = () => this.trade() + this.vehicleProfit()

    totalResidual = () => this.get(['Quote', 'Residual'])

    buildFee = (name, titleId) => {
        let cashdownFee = this.fees().find(f => f.TitleId === titleId)

        if (!cashdownFee) {
            cashdownFee = {
                Name: name,
                Type: 'Capped',
                Amount: 0,
                TaxRate: 0
            }
        }
        return cashdownFee
    }

    quoteParameterTaxes = () => this.get(['QuoteParameters', 'Taxes'])

    taxRate = () => this.get(['Quote', 'TaxRate'])

    montlyUseTax = () => this.get(['Quote', 'MonthlyUseTax'])

    leaseType = () => this.get(['QuoteParameters', 'PaymentFrequency'])

    appliedRebates = () => {
        return this.fees().filter((fee) => {
            return (fee.Amount !== 0) && (fee.TitleId === '10011' || fee.TitleId === '10012')
        })
    }

    totalRebatesAmount = () => {
        const rebateFees = this.fees().filter((fee) => {
            return (fee.Amount !== 0) && (fee.TitleId === '10011' || fee.TitleId === '10012')
        })
        return this.sumFees(rebateFees) * -1
    }

    cashDueOnDelivery = () => {
        if (typeof this.deal.drive_off === 'number') {
            return this.deal.drive_off
        } else {
            return this.monthlyPayment()
        }
    }

    tireFeeTax = () => this.findFeeAmountOrZero('1064')

    taxAmount = () => this.get(['Quote', 'Tax'])

    totalTaxes = () => this.get(['Quote', 'TotalTaxes'])

    balanceDue = () => this.salesSubtotal() + this.totalFeeAmount() + this.taxAmount()

    totalOfPayments = () => _.round(this.financeCharge() + this.balanceDue(), 2)

    totalCashPrice = () => this.quoteCash() - this.totalRebatesAmount()

    taxedFees = () => {
        return this.fees().filter((fee) => {
            if (fee.Amount === 0) return false;
            if (fee.Type === 'PaidOutsideOfClosing') return false;  // PaidOutsideOfClosing means its not part of the calculations
            if (fee.TitleId === '10013') return false;  // Custom Drive off Fee shouldn't be included
            if (fee.CapitalizedTax === 0 && fee.UpfrontTax === 0 && fee.Tax === 0) return false;
            if (!fee.CapitalizedTax && !fee.UpfrontTax && !fee.Tax) return false;
            return true
        })
    }

    monthlyUseTax = () => this.get(['Quote', 'MonthlyUseTax'])

    totalMonthlyUseTax = () => {
        const monthlyUseTax = this.monthlyUseTax()

        let tax = monthlyUseTax * this.deal.term
        if (tax === 0) {
            const taxRate = this.taxRate() / 100
            tax = this.totalBasePayments() * taxRate
        }

        return _.round(tax, 2)
    }

    purchaseMonthlyTax = () => {
        return _.round(this.deal.selling_price * (this.taxRate() / 100), 2)
    }

    totalBasePayments = () => this.get(['Quote', 'TotalBasePayments'])

    totalSalePrice = () => _.round(this.deal.selling_price + this.taxAmount() + this.totalFeeAmount(), 2)

    findFeeFromBackend = (feeId, feeName) => {
        return this.fees().find(fee => fee.TitleId === feeId && fee.Name === feeName)
    }
}

export default QuoteHelper