import Box from "@mui/material/Box";
import React from "react";
import classnames from "classnames";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import {useDealContext} from "../../contexts/DealContext";
import {useUserContext} from "../../contexts/UserContext";
import {DealPageAdditionalInfo} from "./DealPageAdditionalInfo";
import {DealPageSuperUserSection} from "./DealPageSuperUserSection";

export const DealPageDrawer = () => {
    const { isSuperUser } = useUserContext()


    return (
        <React.Fragment>
            <DealPageAdditionalInfo/>

            {
                isSuperUser() &&
                <DealPageSuperUserSection/>
            }
        </React.Fragment>
    )
}