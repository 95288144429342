import React, {useEffect} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import classnames from "classnames";
import Switch from "@mui/material/Switch";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import QuoteHelper from "../../utils/QuoteHelper";
import useFeeStateHook from "../../hooks/useFeeStateHook";
import {useDealContext} from "../../contexts/DealContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";


export const DealPageRegistrationFee = (props) => {
    const {
        isLoading,
        isTabletOrMobile,
    } = props
    const { setDealStateWithUpdate, updateDealResponse, dealState } = useDealContext()

    const getInitalFeeState = (dealState) => {
        const quoteHelper = new QuoteHelper(dealState)
        const registrationFeeOverride = quoteHelper.registrationFeeOverride()
        if (registrationFeeOverride) {
            return registrationFeeOverride
        } else {
            return {
                Id: '10019',
                TitleId: '10019',
                Name: 'Registration Fee',
                Amount: quoteHelper.totalRegistrationFees(),
                Type: 'Upfront'

            }
        }
    }

    const [feeState, setFeeStateWithUpdate] = useFeeStateHook(getInitalFeeState(dealState))

    useEffect(function setFeeInDealState() {
        const quoteHelper = new QuoteHelper(dealState)
        const registrationFeeOverride = quoteHelper.registrationFeeOverride()

        if (!registrationFeeOverride) {
            let newDealState = structuredClone(dealState)
            let allFees = newDealState.selected_quote['Quote']['Fees']
            allFees.push(getInitalFeeState(newDealState))

            newDealState.selected_quote['Quote']['Fees'] = allFees
            setDealStateWithUpdate(newDealState, false)
        }
    }, [])

    useDidMountEffect(function updateFeeAndDealStateFromBE() {
        if (updateDealResponse?.data?.data && !updateDealResponse.loading && !updateDealResponse.error) {
            const newDealState = updateDealResponse?.data?.data
            const quoteHelper = new QuoteHelper(newDealState)
            const registrationFeeOverride = quoteHelper.registrationFeeOverride()

            if (!registrationFeeOverride) {
                setFeeStateWithUpdate(getInitalFeeState(newDealState), false)

                let allFees = newDealState.selected_quote['Quote']['Fees']
                allFees.push(getInitalFeeState(newDealState))

                newDealState.selected_quote['Quote']['Fees'] = allFees
                setDealStateWithUpdate(newDealState, false)
            }
        }
    }, [updateDealResponse])

    if (!feeState) return null;

    const isFinanced = feeState.Type === "Financed"

    return (
        <TableRow
            key={'Registration-Fee-ID'}
            sx={{
                '&:last-child td, &:last-child th': {border: 0},
                height: '10px',
            }}
        >

            <TableCell
                className={'DealPageFee fee-name'}
                scope="row"
                size={'small'}
                width={'60%'}
                align="left"
            >
                Registration Fee
            </TableCell>
            <TableCell
                className={'DealPageFee'}
                align="right"
                size={'small'}
                width={'20%'}
            >
                <Switch
                    disabled={isLoading}
                    checked={isFinanced}
                    onChange={() => {
                        const type = feeState?.Type === 'Financed' ? 'Upfront' : 'Financed'
                        setFeeStateWithUpdate({Type: type})
                    }}
                />
            </TableCell>
            <TableCell
                align="right"
                size={'small'}
                className={'DealPageFee'}
                width={'60%'}
            >
                <CustomCurrencyInput
                    onChange={(value) => setFeeStateWithUpdate({Amount: value}, false)}
                    className={classnames('payment-row_value custom-fee-input', {
                        mobileBlue: isTabletOrMobile && isFinanced,
                        loading: isLoading
                    })}
                    prefix="$"
                    value={feeState.Amount}
                    allowNull={true}
                />
            </TableCell>
        </TableRow>
    )
}